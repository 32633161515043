import axios from "axios";
import ElementUI from "element-ui";
import router from "../router";
import store from "../store/index";

axios.defaults.timeout = 10000;

let token = window.sessionStorage.getItem("token");
if (token) {
  axios.defaults.headers = {
    Authorization: token,
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
  };
} else {
  axios.defaults.headers = {
    Accept: "application/json",
  };
}

axios.interceptors.request.use(
  (config) => {
    if (store.state.token) {
      config.headers.Authorization = store.state.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    try {
      return Promise.resolve(response);
    } catch {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          let apiUrl = error.response.config.url.split("/");
          if (`${apiUrl[3]}/${apiUrl[4]}` === "oauth/token") {
            ElementUI.Message({
              message: error.response.data.message,
              type: "warning",
            });
          } else {
            ElementUI.Message({
              message: "错误请求",
              type: "warning",
            });
          }
          break;
        case 401:
          ElementUI.Message({
            message: "网络错误，请重新登录",
            type: "warning",
          });
          window.sessionStorage.clear();
          setTimeout(() => {
            router.push("/login");
          }, 1000);
          break;
        case 403:
          ElementUI.Message({
            message: "当前用户没有权限",
            type: "warning",
          });
          break;
        case 404:
          ElementUI.Message({
            message: "错误请求",
            type: "warning",
          });
          break;
        case 500:
          ElementUI.Message({
            message: "服务器异常",
            type: "warning",
          });
          break;
      }
      return Promise.reject(error.response);
    }
  }
);
export function fetch(method, url, options) {
  const { data } = options;
  return new Promise((resolve, reject) => {
    if (method === "post") {
      axios
        .post(url, data, options)
        .then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    } else if (method === "delete") {
      axios
        .delete(url + "/" + data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    } else if (method === "patch") {
      axios
        .patch(url + "/" + data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    } else {
      axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    }
  });
}
