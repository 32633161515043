import { fetch } from "@/services/axios";
import axios from "axios";

// const url = "http://192.168.88.131:8070"; // 本地测试url
const url = "http://39.100.149.239:8070"; // 线上url
const deviceUrl = "https://fram.youxi-ai.cn:8090"; // 设备相关暂时用线上

/* 后台管理接口 start */
const token = () => {
  if (window.sessionStorage.getItem("token")) {
    axios.defaults.headers = {
      Authorization: window.sessionStorage.getItem("token"),
      Accept: "application/json",
    };
  }
};

/* constructionUrl */

//登陆接口
const getLogin = (data, options = { data }) =>
  fetch("get", `${url}/oauth/token`, options);

//修改密码
const getUserModifyPwd = (data, options = { data }) =>
  fetch("post", `${url}/user/modifyPwd`, options);

//查询部门列表返回树形结构
const getDeptTree = (data, options = { data }) =>
  fetch("get", `${url}/dept/tree`, options);

//查询设备信息列表(分页)
const getDeviceList = (data, options = { data }) =>
  fetch("post", `${deviceUrl}/device/list`, options);

//添加设备
const getDeviceSave = (data, options = { data }) =>
  fetch("post", `${deviceUrl}/device/save`, options);

//更新设备
const getDeviceUpdate = (data, options = { data }) =>
  fetch("post", `${deviceUrl}/device/update`, options);

//删除设备
const getDeviceDelete = (data, options = { data }) =>
  fetch("post", `${deviceUrl}/device/delete`, options);

//设备服务器
const mqttServer = (data, options = { data }) =>
fetch("get", `${deviceUrl}/device/mqttServer`, options);

//用户列表
const getUserPage = (data, options = { data }) =>
  fetch("get", `${url}/user/page`, options);

//更新用户
// const getUserUpdate = (data, options = { data }) =>
//   fetch("post", `${url}/user/update`, options);

//获取用户信息
const getUserDepInfo = (data, options = { data }) =>
  fetch("post", `${url}/user/userDepInfo`, options);

//更新用户
const getUserUpdate = (data, options = { data }) =>
  fetch("post", `${url}/user/updateUserDep`, options);

//删除用户
const getUserDelete = (data, options = { data }) =>
  fetch("post", `${url}/user/delete`, options);

//添加部门
const getDeptInsert = (data, options = { data }) =>
  fetch("post", `${url}/dept/insert`, options);

//更新部门
const getDeptUpdate = (data, options = { data }) =>
  fetch("post", `${url}/dept/update`, options);

//删除部门
const getDeptDelete = (data, options = { data }) =>
  fetch("post", `${url}/dept/delete`, options);

//查询权限树形列表
const getPermissionTree = (data, options = { data }) =>
  fetch("get", `${url}/permission/tree`, options);

//权限删除
const getPermissionDelete = (data, options = { data }) =>
  fetch("post", `${url}/permission/delete`, options);

//权限新增
const getPermissionInsert = (data, options = { data }) =>
  fetch("post", `${url}/permission/insert`, options);

//权限更新
const getPermissionUpdate = (data, options = { data }) =>
  fetch("post", `${url}/permission/update`, options);

//查询权限树形列表
const getRolePage = (data, options = { data }) =>
  fetch("get", `${url}/role/page`, options);

//角色新增
const getRoleInsert = (data, options = { data }) =>
  fetch("post", `${url}/role/insert`, options);

//角色删除
const getRoleDelete = (data, options = { data }) =>
  fetch("post", `${url}/role/delete`, options);

//角色编辑
const getRoleUpdate = (data, options = { data }) =>
  fetch("post", `${url}/role/update`, options);

//查询角色关联权限
const getRoleQueryRP = (data, options = { data }) =>
  fetch("get", `${url}/role/queryRP`, options);

//编辑角色关联权限
const getRoleUpdateRP = (data, options = { data }) =>
  fetch("post", `${url}/role/updateRP`, options);

//查询角色关联的用户
const getFindBindUser = (data, options = { data }) =>
  fetch("get", `${url}/role/findBindUser`, options);

//查询角色未关联的用户
const getFindNoBindUser = (data, options = { data }) =>
  fetch("get", `${url}/role/findNoBindUser`, options);

//编辑角色关联用户
const getRoleUpdateUP = (data, options = { data }) =>
  fetch("post", `${url}/role/insertUR`, options);

//角色删除用户
const getRoleDeleteUR = (data, options = { data }) =>
  fetch("post", `${url}/role/deleteUR`, options);

export default {
  // 后台
  token,
  getUserModifyPwd,
  getLogin,
  getDeptTree,
  getDeviceList,
  getDeviceSave,
  getDeviceUpdate,
  getDeviceDelete,
  getUserPage,
  getUserDepInfo,
  getUserUpdate,
  getUserDelete,
  getDeptInsert,
  getDeptDelete,
  getDeptUpdate,
  getPermissionTree,
  getPermissionDelete,
  getPermissionInsert,
  getPermissionUpdate,
  getRolePage,
  getRoleInsert,
  getRoleDelete,
  getRoleUpdate,
  getRoleQueryRP,
  getRoleUpdateRP,
  getFindBindUser,
  getFindNoBindUser,
  getRoleUpdateUP,
  getRoleDeleteUR,
  mqttServer,
};
