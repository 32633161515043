import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Api from "@/services/api.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/common.css";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$api = Api;

// input空格验证
Vue.prototype.handleKeyup = (value) => {
  console.log(value);
  return value.replace(/[, ]/g, "");
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
